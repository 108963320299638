import React, { useEffect, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

// import lib
import isEmpty from "is-empty"

//import action
import { toast } from "../../lib/toast"

// import api
import { disable2FA, enable2FA, get2FA } from "../../api/user"
import { Button, Col, Form, Row } from "react-bootstrap"
import { setAccount } from "../../redux/slices/account"

export default function TwoFA() {
  // state
  const [secretData, setSecretData] = useState()
  const [code, setCode] = useState("")
  const [validateError, setValidateError] = useState({})
  const [checkValue, setCheckValue] = useState(false)

  const { account } = useSelector(state => state)

  const history = useNavigate()
  const dispatch = useDispatch()

  // function
  const handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
      return
    }
    setCode(value)
    if (!isEmpty(validateError)) {
      setValidateError({})
    }
  }

  const handleCheckBox = e => {
    const { name, checked } = e.target
    setCheckValue(checked)
  }

  const fetchTwoFA = async () => {
    try {
      const { success, result } = await get2FA()

      if (success) {
        setSecretData(result)
      }
    } catch (err) {
      console.log(err, "------------error")
    }
  }

  const disableTwoFa = async data => {
    let reqData = {
      secret: secretData.secret,
      uri: secretData.uri,
      code: code,
      type: "2FA",
    }

    try {
      const { success, result, message, errors } = await disable2FA(reqData)
      if (success) {
        toast(
          {
            message: message,
            type: "success",
          },
          dispatch,
        )
        setCode("")
        dispatch(setAccount({ twoFAStatus: "disabled", isTwoFA: false }))

        setSecretData(result)
      } else {
        if (errors) {
          setValidateError(errors)
        } else {
          toast(
            {
              message: message,
              type: "error",
            },
            dispatch,
          )
        }
      }
    } catch (err) {
      console.log(err, "------------error")
    }
  }

  const enableTwoFa = async () => {
    if (checkValue === true) {
      let reqData = {
        secret: secretData.secret,
        uri: secretData.uri,
        code: code,
        checkValue: checkValue,
        type: "2FA",
      }
      try {
        const { success, result, message, errors } = await enable2FA(reqData)
        if (success) {
          toast(
            {
              message: message,
              type: "success",
            },
            dispatch,
          )
          dispatch(setAccount({ twoFAStatus: "enabled", isTwoFA: true }))

          setCode("")
          setSecretData(result)
        } else {
          if (errors) {
            setValidateError(errors)
          } else {
            toast(
              {
                message: message,
                type: "error",
              },
              dispatch,
            )
          }
        }
      } catch (err) {
        console.log(err, "------------error")
      }
    }
    else {
      toast(
        {
          message: "Select the checkbox",
          type: "error",
        },
        dispatch,
      )
    }
  }

  useEffect(() => {
    fetchTwoFA()
  }, [])

  return (
    <Row>
      <Col xs={24} sm={24} md={12}>
        <Form className="primary_form">
          <div className="mb-3">
            <h2 className="dash_title">
              Status:{" "}
              <span
                className={
                  `${account && account?.twoFAStatus == "disabled"
                    ? "text-red"
                    : "text-green"} text-capitalize`}
              >
                {account && account?.twoFAStatus}
              </span>
            </h2>
          </div>
          <div className="row com-border">
            <div className="col-lg-6">
              <div className="col-lg-12">
                {(account.isTwoFA === false && account.twoFAStatus === "disabled") &&
                  <div className="input_group_model mb-3">
                    <Form.Label>Your 32 Digit Security Code</Form.Label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        value={secretData && secretData.secret}
                      />
                      <span className="input-group-text">
                        <CopyToClipboard
                          text={secretData && secretData.secret}
                          onCopy={() => {
                            toast(
                              {
                                message: "Copied",
                                type: "success",
                              },
                              dispatch,
                            )
                          }}
                        >
                          <a href="#">
                            <img
                              src={require("../../assets/image/copy-Filled.png")}
                              className="img-fluid"
                              alt="copy-Filled"
                            />
                          </a>
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                }
                <div className="col-lg-12">
                  <div className="order_confrim_model mb-3">
                    <Form.Label>Enter 6 Digit 2FA Code</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      value={code}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{validateError.code}</span>
                  </div>
                </div>
                {(account.isTwoFA === false && account.twoFAStatus === "disabled") &&
                  <div>
                    <Form.Label className="col-lg-4 d-block">Scan the QR Code</Form.Label>
                    <img
                      src={secretData && secretData.imageUrl}
                      width={80}
                      height={80}
                      alt="QR"
                      className="img-fluid mb-3 mx-auto d-block"
                    />
                  </div>
                }
              </div>
            </div>{" "}
            <div className="col-lg-6 two_factor">
              <h5 className="col-lg-4">Note:</h5>
              <p>
                "Enable" Two Factor Authentication as an additional security measures, to
                protect your account. Please use Google Authenticator which you can
              </p>

              <h6 className="col-lg-4">How to Enable:</h6>
              <p>
                -After download all app, open it. Scan the QR code. You'll have 6 digit
                code Enter that 6 digit code into 2FA code box, then click Enable.
              </p>
              <p>-Right down the 32 digit code in a white paper for your security.</p>
              <div className="left-align-button">
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US"
                  target={"_blank"}
                  className="link-button mt-2"
                >
                  {" "}
                  Download Here
                </a>
              </div>
            </div>
            <div className="mb-3">
              {secretData && secretData.twoFaStatus == "disabled" && (
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="checkValue"
                    onChange={handleCheckBox}
                    checked={checkValue}
                    id="backup"
                  />
                  <label className="form-check-label" for="backup">
                    I have backup the code
                  </label>
                </div>
              )}
              <span className="text-danger">{validateError.checkValue}</span>
            </div>
            <div className="form-bottom d-flex gap-3">
              {secretData && secretData.twoFaStatus == "disabled" && (
                <Button onClick={enableTwoFa} className="primary-btn me-3">
                  Enable
                </Button>
              )}
              {secretData && secretData.twoFaStatus == "enabled" && (
                <Button className="primary-btn" onClick={disableTwoFa}>
                  Disable{" "}
                </Button>
              )}
              <Button className="primary-btn" onClick={() => history("/security")}>
                {" "}
                Go Back
              </Button>
            </div>
          </div>
        </Form>
      </Col>
    </Row>
  )
}
